import Vue from 'vue'

const ShopService = {

  all (query) {
    return Vue.http.get('shop/index', query)
  },
  allStoreShops (query) {
    return Vue.http.post('shop/store-shop', query)
  },
  shops () {
    return Vue.http.post('shop/index')
  },

  delete (id) {
    return Vue.http.delete('shop/delete', {
      id: id
    })
  },

  edit (id, shop = null) {
    const endpoint = 'shop/update'

    if (shop === null) {
      return Vue.http.get(endpoint, {
        id: id
      })
    }

    return Vue.http.put(endpoint, shop, {
      id: id
    })
  },
  // 配置商品
  editDpz (id, shop = null) {
    const endpoint = 'shop/update-temp'

    if (shop === null) {
      return Vue.http.get(endpoint, {
        id: id
      })
    }

    return Vue.http.put(endpoint, shop, {
      id: id
    })
  },

  create (shop = null, parentId = 0) {
    const endpoint = 'shop/create'

    if (shop === null) {
      return Vue.http.get(endpoint, {
        parentId: parentId
      })
    }

    return Vue.http.post(endpoint, shop)
  },

  // 批量操作
  // 上架
  upShop (arr) {
    return Vue.http.put('shop/up-shop', arr)
  },
  // 下架
  downShop (arr) {
    return Vue.http.put('shop/down-shop', arr)
  },
  // 会员折扣
  memberDis (arr) {
    return Vue.http.put('shop/member-dis', arr)
  },
  // 删除
  delShop (arr) {
    return Vue.http.delete('shop/del-shop', arr)
  },
  // 估清
  stockL (arr) {
    return Vue.http.post('shop/stock-l', arr)
  },
  // 批量处理外卖状态
  isWaiMai (arr) {
    return Vue.http.post('shop/wm', arr)
  },
  // 商家接单
  jieDan (item) {
    return Vue.http.post('wm/jie-dan', item)
  },
  // 开始配送
  goPs (item) {
    return Vue.http.post('wm/go-ps', item)
  },
  // 配送完成
  goPsOver (item) {
    return Vue.http.post('wm/go-ps-over', item)
  }
}

export default ShopService
