<template>
  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="100px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')">
    <el-form-item
      label="名称"
      prop="product_name"
    >
      <el-col :md="9">
        <el-input
          type="text"
          v-model.trim="formModel.product_name"
        ></el-input>
      </el-col>
    </el-form-item>
    <el-form-item
      label="分类"
      prop="classifySelectArr"
    >
      <el-col :md="12">
        <el-select
          style="width:100%"
          v-model="classifySelectArr"
          @remove-tag="removeTag"
          multiple
          collapse-tags
          placeholder="请选择"
        >
          <el-option
            v-for="(item,index) in classifyArr"
            :key="index"
            :label="item.name"
            :value="item.id"
            :disabled="item.isGlobal == 1"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-form-item>
    <el-form-item
      label="成份"
      prop="product_component"
    >
      <el-col :md="9">
        <el-input
          type="text"
          v-model.trim="formModel.product_component"
        ></el-input>
      </el-col>
    </el-form-item>
    <!-- <div
      v-if="!isEdit"
    >

      <el-form-item
        label="生成素材"
      >
          <el-switch v-model="isCreateTemp" active-text="" inactive-text="">
        </el-switch>
      </el-form-item>

       <el-form-item
        label="选择店铺"
      >
        <el-checkbox-group v-model="branchShopIds">
          <el-checkbox v-for="(item,index) in shops"
          :key="index"  :label="item.id">{{item.name}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </div> -->
    <el-col :md="9">
      <el-form-item
        label="描述"
        prop="product_describe"
      >
        <el-input type="textarea" :rows="4"  v-model.trim="formModel.product_describe"
         placeholder=""></el-input>
      </el-form-item>
    </el-col>

    <el-form-item
      label="价格"
      prop="price"
    >
      <el-col :md="5">
        <el-input
          type="text"
          v-model.trim="formModel.price"
        ></el-input>
      </el-col>
    </el-form-item>
    <el-form-item
      label="灰色价格"
      prop="false_price"
    >
      <el-col :md="5">
        <el-input
          type="text"
          v-model.trim="formModel.false_price"
        ></el-input>
      </el-col>
    </el-form-item>
    <!-- <el-form-item
      label="会员折扣"
      prop="price_member"
    >
      <el-col :md="5">
        <el-input
          type="text"
          v-model.trim="formModel.price_member"
        ></el-input>
      </el-col>
    </el-form-item> -->
    <el-form-item
      label="库存"
      prop="stock"
    >
     <el-button  size='mini' @click="openStock">
      {{(formModel.is_ks_stock === '1')?'不计库存':formModel.stock}}
     </el-button>
    </el-form-item>

    <!-- <div style="display:flex;">
    <el-form-item
      label="列表图"
      prop="formModel.list_map"
    >
      <list-photo
        :avatar="formModel.list_map"
        @on-upload-success="handleListUploadSuccess"
      ></list-photo>
    </el-form-item>
    <el-form-item
      label="详情图"
      prop="formModel.cover_map"
    >
      <view-photo
        :avatar="formModel.cover_map"
        @on-upload-success="handleViewUploadSuccess"
      ></view-photo>
    </el-form-item>
    </div> -->
    <el-form-item
      label="规格"
      prop="flavorSelectArr"
    >
      <el-col :md="12">
        <el-select
          style="width:100%"
          v-model="flavorSelectArr"
          multiple
          collapse-tags
          placeholder="请选择"
        >
          <el-option
            v-for="(item,index) in flavorArr"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-form-item>

    <el-form-item label="是否外卖">
      <el-switch
        v-model="formModel.is_wm"
        :active-text="formModel.is_wm == 1?'是':'否'"
      >
      </el-switch>
    </el-form-item>

    <el-form-item>
      <el-button
        size="medium"
        type="primary"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>
     <!-- 弹出窗口内容 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="400">
      <div class="stock-box">
        <div class="stock-item">
          当前库存
          <el-input type="number" :min="0" v-model.trim="stock.stock"/>
        </div>
        <div class="stock-item">
          每日最大数量
          <el-input type="number" :min="0" v-model.trim="stock.max_stock"/>
        </div>
        <div class="stock-item">
          <el-checkbox v-model="stock.is_refresh">每日刷新重置数量</el-checkbox>
        </div>
        <div class="stock-item">
          <el-checkbox v-model="stock.is_ks_stock">不计库存</el-checkbox>
        </div>
        <el-button  @click="stockSave()">确定</el-button>
      </div>
    </el-dialog>
  </el-form>

</template>

<script>
// import Uploader from '@admin/components/uploaders/Uploader'
import ShopService from '@admin/services/ShopService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'ProductForm',
  // components: { listPhoto: Uploader, viewPhoto: Uploader },
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object
    }
  },
  data () {
    return {
      dialogVisible: false,
      isCreateTemp: false, // 是否生成素材
      classifyArr: [], // 所有的分类选项
      classifySelectArr: [], // 当前选择的分类
      flavorArr: [],
      flavorSelectArr: [],
      branchShopIds: [], // 店铺已选列表
      shops: [],
      submitLoading: false,
      formRules: {
        product_name: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur'
          }
        ],
        product_describe: [
          {
            required: true,
            message: '请输入描述',
            trigger: 'blur'
          }
        ],
        product_component: [
          {
            required: true,
            message: '商品描述不能为空',
            trigger: 'blur'
          }
        ]
        // cover_map: [
        //   {
        //     required: true,
        //     message: '详情图不能为空',
        //     trigger: 'blur'
        //   }
        // ],
        // list_map: [
        //   {
        //     required: true,
        //     message: '列表不能为空',
        //     trigger: 'blur'
        //   }
        // ]
      },
      stock: {
        stock: 0,
        max_stock: 0,
        is_refresh: false,
        is_ks_stock: true
      },
      formModel: null
    }
  },
  async created () {
    const { data } = await flatry(ShopService.create())

    if (data) {
      this.flavorArr = Object.assign(data.data.flavor)
      this.classifyArr = Object.assign(data.data.classify)
      this.shops = Object.assign(data.data.shops)
      this.classifySelectArr =
                Object.assign([], this.product.classifySelectArr) || []
      this.flavorSelectArr =
                Object.assign([], this.product.flavorSelectArr) || []
      // 查询所选的分类里面是否拥有全局的分类
      for (let i = 0; i < this.classifyArr.length; i++) {
        for (let j = 0; j < this.classifySelectArr.length; j++) {
          if (this.classifyArr[i].id === this.classifySelectArr[j]) {
            if (
              this.classifyArr[i].isGlobal === 1 ||
                            this.classifyArr[i].isGlobal === '1'
            ) {
              this.classifyArr[i].isGlobal = true
            }
          }
        }
      }
      this.classifyArr.forEach(element => {
        if (element.isGlobal === 0 || element.isGlobal === '0') {
          element.isGlobal = false
        }
      })
    }
  },
  async mounted () {
    this.formModel = Object.assign(this.product)
  },
  methods: {
    // 移除分类
    removeTag (val) {
      for (let index = 0; index < this.classifyArr.length; index++) {
        if (this.classifyArr[index].id === val) {
          if (this.classifyArr[index].isGlobal) {
            this.$message.warning('不能取消全局分类')
            this.classifySelectArr.push(val)
            break
          }
        }
      }
    },
    // 打开选择库存model
    openStock () {
      let stock = Object.assign({
        stock: this.formModel.stock,
        max_stock: this.formModel.max_stock,
        is_refresh: this.formModel.is_refresh === '1',
        is_ks_stock: this.formModel.is_ks_stock === '1'
      })
      this.stock = stock
      this.dialogVisible = true
    },
    //  保存库存
    stockSave () {
      this.formModel.stock = this.stock.stock
      this.formModel.max_stock = this.stock.max_stock
      this.formModel.is_refresh = this.stock.is_refresh === true ? '1' : '0'
      this.formModel.is_ks_stock = this.stock.is_ks_stock === true ? '1' : '0'
      this.dialogVisible = false
    },
    // handleViewUploadSuccess (avatarUrl) {
    //   this.formModel.cover_map = avatarUrl
    // },
    // handleListUploadSuccess (avatarUrl) {
    //   this.formModel.list_map = avatarUrl
    // },

    // 提交
    handleFormSubmit (formName) {
      if (Number(this.formModel.price) < 1) {
        this.$message.warning('价格不能小于1')
        return false
      }
      if (Number(this.formModel.false_price) < 1) {
        this.$message.warning('价格不能小于1')
        return false
      }

      this.formModel.flavorSelectArr = this.flavorSelectArr
      this.formModel.classifySelectArr = this.classifySelectArr
      this.formModel.branchShopIds = this.branchShopIds
      // this.formModel.isCreateTemp = this.isCreateTemp
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }
        this.submitLoading = true

        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.stock-box {
  padding:0 40px;
  .stock-item{
    margin-bottom: 20px;
  }
}
</style>
