<template>
 <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <shop-form v-if="product"
                :submit-text="pageTitle"
                :product="product"
                :isEdit="true"
                @on-submit="editProduct">
    </shop-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import ShopForm from '@admin/views/shop/components/_EditForm'
import ShopService from '@admin/services/ShopService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  components: { PlaceholderForm, ShopForm },
  data () {
    return {
      pageTitle: '编辑商品',
      product: null
    }
  },
  async created () {
    let dpz = this.$router.currentRoute.query.dpz
    let storeId = this.$router.currentRoute.query.shopId
    // 如果是配置商品 请求配置商品的数据
    if (dpz === true || dpz === 'true') {
      const { data } = await flatry(ShopService.editDpz(this.$router.currentRoute.query.id))
      if (data) {
        this.product = Object.assign({ storeId: storeId }, data.data)
      }
    } else {
      const { data } = await flatry(ShopService.edit(this.$router.currentRoute.query.id))
      if (data) {
        this.product = data.data
      }
    }
  },
  methods: {
    async editProduct (product, success, callback) {
      const { data } = await flatry(ShopService.edit(product.id, product))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/shop/update', query: { id: data.data, dpz: false } })
        success()
      }

      callback()
    }
  }
}
</script>

<style scoped>

</style>
